import React from "react";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import { NAV_HEIGHT } from "../../../constants";

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  &:first-child {
    margin-top: -${NAV_HEIGHT / 2}px;
  }
`;

const Hero = ({ children, ...otherProps }) => (
  <Parallax {...otherProps}>
    <Overlay>{children}</Overlay>
  </Parallax>
);

export default Hero;
