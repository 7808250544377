import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";

import Menu from "./components/Menu";
import Home from "./components/Home";
import About from "./components/About";
import Contact, { ContactDetails } from "./components/Contact";
import Services from "./components/Services";
import Portfolio from "./components/Portfolio";

import "./App.css";
import Content from "./components/Sections/Content";

const ContactHeading = styled.h2`
  font-size: 1.3rem;
`;

const menuItems = [
  {
    to: "/",
    name: "Home"
  },

  {
    to: "/about",
    name: "About"
  },
  {
    to: "/services",
    name: "Our Services"
  },
  {
    to: "/portfolio",
    name: "Portfolio"
  },
  {
    to: "/contact",
    name: "Contact"
  }
];

class App extends React.Component {
  state = {
    isMenuOpen: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        isMenuOpen: false
      });
    }
  }

  handleToggleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  render() {
    const { isMenuOpen } = this.state;

    return (
      <div>
        <Menu
          handleMenuToggle={this.handleToggleMenu}
          menuItems={menuItems}
          isMenuOpen={isMenuOpen}
        />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/services" component={Services} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/contact" component={Contact} />
          <Route component={Home} />
        </Switch>
        <Content>
          <ContactHeading>Contact</ContactHeading>
          <ContactDetails />
        </Content>
      </div>
    );
  }
}

export default withRouter(App);
