import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HeroButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font: 700 1.2rem "Montserrat", sans-serif;
  padding: 1em 2em;
  letter-spacing: 0.05rem;
  box-shadow: inset 0 0 0 2px #ffffff;
  color: #ffffff;
  transition: color 0.25s 0.0833333333s;
  position: relative;
  opacity: 1;
  margin-right: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
  text-transform: uppercase;
`;

export const Portfolio = () => (
  <Link to="/portfolio">
    <HeroButton className="hero">View Portfolio</HeroButton>
  </Link>
);

export const Services = () => (
  <Link to="/services">
    <HeroButton className="hero">Our Services</HeroButton>
  </Link>
);

export const About = () => (
  <Link to="/about">
    <HeroButton className="hero">About</HeroButton>
  </Link>
);

export const Contact = () => (
  <Link to="/contact">
    <HeroButton className="hero">Contact</HeroButton>
  </Link>
);
