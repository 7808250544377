import React from "react";
import { Parallax } from "react-parallax";
import { map, filter } from "lodash";
import styled from "styled-components";
import Lightbox from "react-images";
import { Contact } from "../Buttons";
import Divider from "../Sections/Divider";
import { IMAGE_URL, NAV_HEIGHT, projects, images } from "../../constants";

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  &:first-child {
    margin-top: -${NAV_HEIGHT / 2}px;
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  height: 300px;
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 1em;
  width: 1400px;
`;

const Image = styled.img`
  flex: auto;
  height: 250px;
  min-width: 150px;
  margin: 8px 8px 8px 8px; /* Some gutter */
  cursor: pointer;
  opacity: 0.9;
`;

const SubHeading = styled.h2`
  padding: 0 1em;
`;

class Portfolio extends React.Component {
  state = {
    isOpen: false,
    currentImage: 0
  };

  open = position => {
    this.setState({
      currentImage: position,
      isOpen: true
    });
  };

  close = () => {
    this.setState({
      currentImage: 0,
      isOpen: false
    });
  };

  prev = () => {
    if (this.state.currentImage > 0) {
      this.setState({
        currentImage: this.state.currentImage - 1
      });
    }
  };

  next = () => {
    if (this.state.currentImage < images.length) {
      this.setState({
        currentImage: this.state.currentImage + 1
      });
    }
  };

  renderImage = ({ type, alt, thumbnail, src, position }) => (
    <Image
      onClick={() => this.open(position)}
      src={thumbnail}
      alt={alt}
      key={position}
    />
  );

  renderImageParallax = (bgImage, title, type) => (
    <>
      <Parallax bgImage={bgImage} strength={300}>
        <Section>
          <SubHeading>{title}</SubHeading>
        </Section>
      </Parallax>
      <Parallax>
        <Centered>
          <Content>{map(filter(images, { type }), this.renderImage)}</Content>
        </Centered>
      </Parallax>
    </>
  );

  render() {
    return (
      <div>
        <Lightbox
          images={images}
          onClose={this.close}
          onClickPrev={this.prev}
          onClickNext={this.next}
          currentImage={this.state.currentImage}
          isOpen={this.state.isOpen}
        />
        <Parallax bgImage={`${IMAGE_URL}hero/portfolio.jpg`} strength={500}>
          <Overlay>
            <h1>Portfolio</h1>
          </Overlay>
        </Parallax>
        <Parallax>
          <Centered>
            <Content>
              {map(filter(images, { type: "past-projects" }), this.renderImage)}
            </Content>
          </Centered>
        </Parallax>
        {this.renderImageParallax(projects[5].src, "Paving", "paving")}
        {this.renderImageParallax(projects[9].src, "Steps", "steps")}
        {this.renderImageParallax(projects[6].src, "Timber", "timber")}
        {this.renderImageParallax(projects[1].src, "Paths", "paths")}
        {this.renderImageParallax(
          projects[5].src,
          "Walls and Cobbles",
          "walls-and-cobbles"
        )}
        {this.renderImageParallax(projects[3].src, "Bespoke", "bespoke")}
        {this.renderImageParallax(
          projects[4].src,
          "Under Construction",
          "under-construction"
        )}
        <Divider bgImage={projects[7].src} strength={500}>
          <Contact />
        </Divider>
      </div>
    );
  }
}

export default Portfolio;
