import React from "react";
import styled from "styled-components";
import { Parallax } from "react-parallax";

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  height: 300px;
`;

const Divider = ({ children, ...otherProps }) => (
  <Parallax {...otherProps}>
    <Section>{children}</Section>
  </Parallax>
);

export default Divider;
