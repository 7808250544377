import React from "react";
import { NavLink } from "react-router-dom";
import ScrollLock from "react-scrolllock";
import styled from "styled-components";
import { map } from "lodash";

import Logo from "../../logo.png";

import "./index.css";

const Header = styled.header`
  background: #000000;
  z-index: 1;
  width: 100%;
`;

const Content = styled.div`
  padding: 0.6em 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  > li {
    display: inline-block;
  }

  @media screen and (max-width: 750px) {
    display: none;
  }
`;

const MobileNavList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 2em 2em 4em;
  background: #ffffff;
  color: #000000;
  height: calc(100vh - 180px);
  @keyframes slide {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation: 0.15s ease-out 0s 1 slide;

  @media screen and (min-width: 750px) {
    display: none;
  }

  > li {
    padding: 1em;
    border-bottom: 1px solid #000000;
  }

  > li > a {
    color: #000000;
  }
  overflow: scroll;
`;

const MenuIcon = styled.div`
  @media screen and (min-width: 750px) {
    display: none;
  }
`;

const MenuLink = styled(NavLink)`
  padding: 5px 15px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const renderItem = (ref, { to, name }) => (
  <li key={`${ref}-${name}`}>
    <MenuLink to={to} activeClassName="active">
      {name}
    </MenuLink>
  </li>
);

const Menu = ({ handleMenuToggle, menuItems, isMenuOpen }) => (
  <Header>
    <Content>
      <NavLink to="/">
        <img height="75" width="125" src={Logo} alt="Rock Solid Landscapes" />
      </NavLink>
      <MenuIcon
        onClick={handleMenuToggle}
        className={`hamburger hamburger--slider js-hamburger ${
          isMenuOpen ? "is-active" : ""
        }`}
      >
        <div className="hamburger-box">
          <div className="hamburger-inner" />
        </div>
      </MenuIcon>
      <NavList>{map(menuItems, item => renderItem("nav", item))}</NavList>
    </Content>
    {isMenuOpen && (
      <MobileNavList>
        <ScrollLock />
        {map(menuItems, item => renderItem("mobile", item))}
      </MobileNavList>
    )}
  </Header>
);

export default Menu;
