import { flatten, startCase, times } from "lodash";

export const IMAGE_URL =
  "https://s3.eu-west-1.amazonaws.com/rock-solid-landscapes-images/";
export const NAV_HEIGHT = 90;

const buildImagePath = (prefix, max) =>
  times(max, x => ({
    alt: startCase(prefix),
    src: `${IMAGE_URL}portfolio/${prefix}/${x + 1}.jpg`,
    thumbnail: `${IMAGE_URL}portfolio/${prefix}/${x + 1}-thumbnail.jpg`,
    type: prefix
  }));

export const projects = buildImagePath("past-projects", 10);
const portfolio = buildImagePath("past-projects", 9);
const paving = buildImagePath("paving", 9);
const steps = buildImagePath("steps", 4);
const timber = buildImagePath("timber", 7);
const paths = buildImagePath("paths", 9);
const walls = buildImagePath("walls-and-cobbles", 4);
const bespoke = buildImagePath("bespoke", 4);
const construction = buildImagePath("under-construction", 4);

export const images = flatten([
  portfolio, //Shortened past projects
  paving,
  steps,
  timber,
  paths,
  walls,
  bespoke,
  construction
]);

images.forEach((i, index) => {
  i.position = index;
});
