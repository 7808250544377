import React from "react";

import Hero from "../Sections/Hero";
import Divider from "../Sections/Divider";
import Content from "../Sections/Content";

import { IMAGE_URL, projects } from "../../constants";
import { About } from "../Buttons";

const Home = () => (
  <React.Fragment>
    <Hero bgImage={`${IMAGE_URL}hero/home.jpg`} strength={500}>
      <h1>Rock Solid Landscapes</h1>
    </Hero>
    <Content>
      <p>
        Rock Solid Landscapes Ltd. comprises an expert team who work tirelessly
        to achieve a high quality finish throughout your outdoor space.
      </p>
      <p>
        We use only the finest natural materials which are chosen and installed
        with care and attention to detail on all elements of your project.
      </p>
      <p>
        We work directly with garden designers and our private clients to
        deliver projects ranging from compact urban gardens to major landscaping
        of rural country estates.
      </p>
    </Content>
    <Divider bgImage={projects[0].src} strength={300}>
      <About />
    </Divider>
  </React.Fragment>
);

export default Home;
