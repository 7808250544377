import React from "react";
import styled from "styled-components";

import { NAV_HEIGHT } from "../../constants";

const Content = styled.div`
  height: calc(25vh - ${NAV_HEIGHT}px);
`;

export const ContactDetails = () => (
  <React.Fragment>
    <p>
      <a
        href="mailto:info@rocksolidlandscapes.co.uk?Subject=Landscaping"
        target="_top"
      >
        info@rocksolidlandscapes.co.uk
      </a>
    </p>
    <p>
      Mobile:&#160;
      <a href="tel:07740428537">07740&#160;428&#160;537</a>
    </p>
    <p>
      Office:&#160;
      <a href="tel:01173292912">0117&#160;329&#160;2912</a>
    </p>
    <p>Rock Solid Landscapes LTD - Villiers Road Bristol BS5 OJH</p>
  </React.Fragment>
);

const Contact = () => <Content />;

export default Contact;
