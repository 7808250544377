import React from "react";

import { IMAGE_URL } from "../../constants";
import Hero from "../Sections/Hero";
import Divider from "../Sections/Divider";
import Content from "../Sections/Content";
import { Services } from "../Buttons";
import { projects } from "../../constants";

const About = () => (
  <React.Fragment>
    <Hero bgImage={`${IMAGE_URL}hero/about.jpg`} strength={500}>
      <h1>About</h1>
    </Hero>
    <Content>
      <p>
        Rock Solid Landscapes Ltd. was founded by Guy Waite who, with over a
        decade of hands-on experience in the landscaping business in East Anglia
        and Greater London, has developed a passion for creating exciting
        outdoor spaces.
      </p>
      <p>
        Trained as a project manager for a leading BALI accredited landscaping
        organization, Guy has a proven track record in delivering outstanding
        projects within time and on budget.
      </p>
      <p>
        A relocation to Bristol afforded him the opportunity to establish Rock
        Solid Landscapes Ltd. to allow the development of his passion for
        landscaping.
      </p>
      <p>
        Rock Solid is based in Bristol but our team is available to travel
        throughout the South-West.
      </p>
    </Content>
    <Divider bgImage={projects[1].src} strength={300}>
      <Services />
    </Divider>
  </React.Fragment>
);

export default About;
