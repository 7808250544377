import React from "react";
import { map } from "lodash";
import styled from "styled-components";
import { projects, IMAGE_URL } from "../../constants";
import Hero from "../Sections/Hero";
import Content from "../Sections/Content";
import Divider from "../Sections/Divider";
import { Portfolio } from "../Buttons";

const images = [
  {
    name: "Courtyards and Patios",
    src: `${IMAGE_URL}services/courtyards.jpg`
  },
  {
    name: "Hand Laid Cobbles",
    src: `${IMAGE_URL}services/cobbles.jpg`
  },
  {
    name: "Woodwork and Exterior joinery",
    src: `${IMAGE_URL}services/joinery.jpg`
  },
  {
    name: "Outdoor Kitchens",
    src: `${IMAGE_URL}services/kitchens.jpg`
  },
  {
    name: "Stone Walling",
    src: `${IMAGE_URL}services/stone.jpg`
  },
  {
    name: "Bespoke Fencing and Screening",
    src: `${IMAGE_URL}services/fencing.jpg`
  },
  {
    name: "Decorative and Structural Brickwork",
    src: `${IMAGE_URL}services/brickwork.jpg`
  },
  {
    name: "Timber Framed Outbuildings and Pergolas",
    src: `${IMAGE_URL}services/outbuildings.jpg`
  },
  {
    name: "Water Features and Ornamental Ponds",
    src: `${IMAGE_URL}services/water.jpg`
  },
  {
    name: "Exterior fireplaces and Firepits",
    src: `${IMAGE_URL}services/paths.jpg`
  }
];

const Image = styled.img`
  width: 340px;
  height: 200px;
  padding: 1em;
  opacity: 1;
  transition: opacity 0.2s ease-out;
`;

const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1em 0;
  width: 100%;
`;

const ImageText = styled.h3`
  margin: 0 0 1em;
  font-size: 1em;
  padding: 0 1em;
  max-width: 340px;
`;

class Services extends React.Component {
  state = {
    isOpen: false,
    currentImage: 0,
    images: []
  };

  renderImage = ({ name, src, images }, key) => (
    <div key={key}>
      <Image src={src} alt={name} />
      <ImageText>{name}</ImageText>
    </div>
  );

  render() {
    return (
      <div>
        <Hero bgImage={`${IMAGE_URL}hero/services.jpg`} strength={500}>
          <h1>Our Services</h1>
        </Hero>
        <Content>
          <ImageGrid>{map(images, this.renderImage)}</ImageGrid>
        </Content>
        <Divider bgImage={projects[9].src} strength={300}>
          <Portfolio />
        </Divider>
      </div>
    );
  }
}

export default Services;
