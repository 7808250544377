import React from "react";
import { Parallax } from "react-parallax";
import styled from "styled-components";

const Body = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
  text-align: center;
  width: 80%;
  margin-left: 10%;
`;

const Content = ({ children }) => (
  <Parallax>
    <Body>{children}</Body>
  </Parallax>
);

export default Content;
